import {
	CartActions,
	CartState,
	CartItem,
	CART_ADD_ITEM,
	CART_EDIT_QUANTITY,
	CART_CREATE_CHECKOUT_REQUEST,
	CART_CREATE_CHECKOUT_SUCCESS,
	CART_REMOVE_ITEM,
	CART_UPDATE_CHECKOUT,
	CART_EDIT_NOTE,
	CART_CLEAR,
	CART_APPLY_DISCOUNT
} from './types'

export const initialCartState: CartState = {
	items: {},
	ready: false,
	total: 0
}

const getTotal = (items: { [id: string]: CartItem }) => {
	return Object.values(items).reduce(
		(a, c) => a + c.quantity * parseFloat(c.variant.priceV2.amount),
		0
	)
}

export function cart(state = initialCartState, action: CartActions): CartState {
	switch (action.type) {
		case CART_ADD_ITEM: {
			let quantity = action.item.quantity

			if (state.items[action.item.id]?.quantity) {
				quantity += state.items[action.item.id].quantity
			}

			const items = {
				...state.items,
				[action.item.id]: {
					...action.item,
					quantity
				}
			}

			const total = getTotal(items)

			return {
				...state,
				items,
				total,
				ready: false,
				lastAction: {
					type: 'ADD',
					itemId: action.item.id,
					timestamp: action.timestamp
				}
			}
		}
		case CART_REMOVE_ITEM: {
			const { [action.id]: _, ...items } = state.items

			const total = getTotal(items)

			return {
				...state,
				items,
				total,
				ready: false,
				lastAction: {
					type: 'REMOVE',
					itemId: action.id
				}
			}
		}
		case CART_EDIT_QUANTITY: {
			const items = {
				...state.items,
				[action.id]: {
					...state.items[action.id],
					quantity: action.quantity
				}
			}

			const total = getTotal(items)

			return {
				...state,
				items,
				total,
				ready: false,
				lastAction: {
					type: 'EDIT',
					itemId: action.id
				}
			}
		}
		case CART_CREATE_CHECKOUT_REQUEST:
			return {
				...state,
				ready: false
			}
		case CART_CREATE_CHECKOUT_SUCCESS:
			return {
				...state,
				checkout: action.checkout,
				ready: true
			}
		case CART_UPDATE_CHECKOUT:
			return {
				...state,
				checkout: action.checkout,
				ready: true
			}
		case CART_APPLY_DISCOUNT:
			return {
				...state,
				discount: {
					code: action.code
				}
			}
		case CART_REMOVE_ITEM:
			return {
				...state,
				discount: undefined
			}
		case CART_EDIT_NOTE:
			return {
				...state,
				items: {
					...state.items,
					[action.id]: {
						...state.items[action.id],
						note: action.note
					}
				},
				ready: false
			}
		case CART_CLEAR:
			return initialCartState
		default:
			return state
	}
}
