import { useEffect, useRef } from 'react'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { getStoreAndPersistor } from 'store/reducers'
import { ApolloProvider } from '@apollo/client'
import { client } from 'services/storefront'
import NProgress from 'nprogress'
import smoothscroll from 'smoothscroll-polyfill'
import { Meta } from 'components/page/meta'
import '../styles/global.css'

const { store, persistor } = getStoreAndPersistor()

NProgress.configure({ showSpinner: false, speed: 400 })

type Props = AppProps & {
	err?: (Error & { statusCode?: number }) | null
}

const App = ({ Component, pageProps, err }: Props) => {
	const router = useRouter()

	const timeoutId = useRef<NodeJS.Timeout>()

	useEffect(() => {
		smoothscroll.polyfill()

		const handleRouteChangeStart = () => {
			timeoutId.current = setTimeout(() => {
				NProgress.start()
			}, 400)
		}

		const handleRouteChangeComplete = () => {
			if (timeoutId.current) clearTimeout(timeoutId.current)
			NProgress.done()
		}

		const handleRouteChangeError = () => {
			if (timeoutId.current) clearTimeout(timeoutId.current)
			NProgress.done()
		}

		router.events.on('routeChangeStart', handleRouteChangeStart)
		router.events.on('routeChangeComplete', handleRouteChangeComplete)
		router.events.on('routeChangeError', handleRouteChangeError)

		return () => {
			router.events.off('routeChangeStart', handleRouteChangeStart)
			router.events.off('routeChangeComplete', handleRouteChangeComplete)
			router.events.off('routeChangeError', handleRouteChangeError)
		}
	}, [])

	return (
		<>
			<Meta seo={pageProps.seo} />
			<ApolloProvider client={client}>
				<Provider store={store}>
					<PersistGate persistor={persistor}>
						<Component {...pageProps} err={err} />
					</PersistGate>
				</Provider>
			</ApolloProvider>
			{router.pathname !== '/danke' && (
				<Script
					id='trusted-shops-script'
					data-desktop-y-offset='0'
					data-mobile-y-offset='0'
					data-desktop-disable-reviews='false'
					data-desktop-enable-custom='false'
					data-desktop-position='right'
					data-desktop-custom-width='156'
					data-desktop-enable-fadeout='false'
					data-disable-mobile='false'
					data-disable-trustbadge='false'
					data-mobile-custom-width='156'
					data-mobile-disable-reviews='false'
					data-mobile-enable-custom='false'
					data-mobile-position='left'
					data-mobile-enable-topbar='false'
					data-mobile-enable-fadeout='true'
					data-color-scheme='light'
					charSet='UTF-8'
					src='//widgets.trustedshops.com/js/X3226FBFBC0FBC8EB4B45043A545E5B2A.js'
				/>
			)}
		</>
	)
}

export default App
