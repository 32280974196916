import { combineReducers, createStore, applyMiddleware, compose, Middleware } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync'
import expireReducer from 'redux-persist-expire'

import { cart, initialCartState } from './cart/reducer'

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
	}
}

const devTools = typeof window != 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
const composeEnhancers = devTools || compose

const reducer = combineReducers({ cart })

const persistedReducer = persistReducer(
	{
		key: 'root',
		storage,
		transforms: [
			expireReducer('cart', {
				expireSeconds: 10000,
				expiredState: initialCartState,
				autoExpire: true
			})
		]
	},
	reducer
)

export type RootState = ReturnType<typeof persistedReducer>

export const getStoreAndPersistor = () => {
	const middlewares: Middleware[] = [thunk]

	if (typeof window !== 'undefined') {
		middlewares.push(createStateSyncMiddleware({ blacklist: ['persist/PERSIST'] }))
	}

	const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)))

	initMessageListener(store)

	const persistor = persistStore(store)

	return { store, persistor }
}
