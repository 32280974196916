import Head from 'next/head'
import { useRouter } from 'next/router'

export interface SEOProps {
	title?: string | null
	description?: string | null
	image?: string | null
	url?: string | null
	hideBranding?: boolean
}

interface Props {
	seo?: SEOProps
}

export const Meta = ({ seo }: Props) => {
	const router = useRouter()

	const title = seo?.title
		? `${seo.title}${seo.hideBranding ? '' : ' | Edomo'}`
		: 'Edomo - Ein gemeinsamer Online-Shop'

	const description = seo?.description
		? seo.description.length > 155
			? seo.description.substring(0, 153) + '...'
			: seo.description
		: 'Entdecke die Vielfalt unserer Region. Ehrliche Produkte von echten Menschen. Willkommen bei Edomo.'

	return (
		<Head>
			<title>{title}</title>
			<meta name='description' content={description} key='description' />
			<meta property='og:title' content={title} />
			<meta
				property='og:url'
				content={seo?.url ? seo.url : `https://www.edomo.de${router.asPath}`}
			/>
			<meta
				property='og:image'
				content={
					seo?.image ||
					'https://storage.googleapis.com/erzfreunde-strapi-uploads/edomo_og_img_792dac771d/edomo_og_img_792dac771d.jpg'
				}
			/>
			<meta property='og:description' content={description} />
			<meta property='og:site_name' content='Edomo' />
			<meta name='mode-env' content={process.env.MODE} />
			{process.env.MODE === 'dev' && <meta name='robots' content='noindex' />}
			<meta charSet='UTF-8' key='charset' />
			<meta name='viewport' content='initial-scale=1.0, width=device-width' key='viewport' />
			<link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
			<link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
			<link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
			<link rel='manifest' href='/site.webmanifest' />
			<link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
			<script
				type='text/javascript'
				dangerouslySetInnerHTML={{
					__html: `var _iub = _iub || []; _iub.csConfiguration = {"tcfPurposes":{"2":false,"3":false,"4":false,"5":false,"6":false},"consentOnContinuedBrowsing":false,"perPurposeConsent":true,"purposes":"1,3,4,5","lang":"de","siteId":2045288,"floatingPreferencesButtonDisplay":false,"cookiePolicyId":16780310,"cookiePolicyUrl":"/datenschutz-embed", "banner":{ "brandBackgroundColor":"#fff","brandTextColor":"#000","logo":"https://www.edomo.de/edomo-logo.svg","acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#237d00","acceptButtonCaptionColor":"white","customizeButtonColor":"rgba(218, 218, 218, 1)","customizeButtonCaptionColor":"#4D4D4D","rejectButtonDisplay":true,"rejectButtonColor":"#ffffff","rejectButtonCaptionColor":"#393939","position":"float-bottom-left","textColor":"#ebebeb","backgroundColor":"#2b2b31","fontSizeBody":"14px" }};`
				}}
			/>
			<script
				type='text/javascript'
				src='//cdn.iubenda.com/cs/iubenda_cs.js'
				charSet='UTF-8'
				async
			/>
			<script src='https://apps.elfsight.com/p/platform.js' />
			{process.env.MODE === 'production' && (
				<>
					<script
						data-domain='edomo.de'
						data-api='https://plausible.ist.so'
						dangerouslySetInnerHTML={{
							__html: `\
!function(){"use strict";var a=window.location,r=window.document,o=r.currentScript,s=o.getAttribute("data-api")+"/api/event";
function t(t,e){if(/^localhost$|^127(\.[0-9]+){0,2}\.[0-9]+$|^\[::1?\]$/.test(a.hostname)||"file:"===a.protocol)return;
var i={};i.n=t,i.u=a.href,i.d=o.getAttribute("data-domain"),i.r=r.referrer||null,i.w=window.innerWidth,e&&e.meta&&(i.m=JSON.stringify(e.meta)),e&&e.props&&(i.p=e.props);
var n=new XMLHttpRequest;n.open("POST",s,!0),n.setRequestHeader("Content-Type","text/plain"),n.send(JSON.stringify(i)),n.onreadystatechange=function(){4===n.readyState&&e&&e.callback&&e.callback()}}
var e=window.plausible&&window.plausible.q||[];window.plausible=t;for(var i,n=0;n<e.length;n++)t.apply(this,e[n]);
function p(){i!==a.pathname&&(i=a.pathname,t("pageview"))}var w,d=window.history;d.pushState&&(w=d.pushState,d.pushState=function(){w.apply(this,arguments),p()},
window.addEventListener("popstate",p)),"prerender"===r.visibilityState?r.addEventListener("visibilitychange",function(){i||"visible"!==r.visibilityState||p()}):p()}();`
						}}
					/>
					<script
						type='text/plain'
						className='_iub_cs_activate-inline'
						data-iub-purposes='4'
						dangerouslySetInnerHTML={{
							__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-5LSFZDK');`
						}}
					/>
					<script
						async
						type='text/plain'
						className='_iub_cs_activate'
						data-iub-purposes='4'
						src='https://www.googletagmanager.com/gtag/js?id=UA-195471582-1'
					/>
					<script
						dangerouslySetInnerHTML={{
							__html: `window.dataLayer = window.dataLayer || []; function gtag(){ dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', 'UA-195471582-1');`
						}}
					/>
					<script
						type='text/plain'
						className='_iub_cs_activate-inline'
						data-iub-purposes='4'
						dangerouslySetInnerHTML={{
							__html: `!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '2081213598696988');
          fbq('track', 'PageView');`
						}}
					/>
					<script
						type='text/plain'
						className='_iub_cs_activate-inline'
						data-iub-purposes='4'
						dangerouslySetInnerHTML={{
							__html: `(function(h,o,t,j,a,r){
								h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
								h._hjSettings={hjid:2430577,hjsv:6};
								a=o.getElementsByTagName('head')[0];
								r=o.createElement('script');r.async=1;
								r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
								a.appendChild(r);
								})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
						}}
					/>
				</>
			)}
		</Head>
	)
}
