import { ApolloClient, DocumentNode, InMemoryCache } from '@apollo/client'
import { GetServerSidePropsContext } from 'next'
import config from 'etc/config.json'

export const client = new ApolloClient({
	uri: process.env.MODE === 'dev' ? config.STOREFRONT_ENDPOINT_DEV : config.STOREFRONT_ENDPOINT,
	cache: new InMemoryCache(),
	headers: {
		'X-Shopify-Storefront-Access-Token':
			process.env.MODE === 'dev'
				? config.STOREFRONT_ACCESS_TOKEN_DEV
				: config.STOREFRONT_ACCESS_TOKEN,
		Accept: 'application/json'
	}
})

interface QueryArguments {
	context: GetServerSidePropsContext
	query: DocumentNode
	variables?: Record<string, any>
}

export const serverSideQuery = async <T>({ query, variables, context }: QueryArguments) => {
	const headers = {
		'x-forwarded-for': context.req.headers['x-forwarded-for'] as string
	}

	if (!headers['x-forwarded-for']) {
		throw new Error('Storefront Server-Side Query: X-Forwarded-For header missing')
	}

	const result = await client.query<T>({
		query,
		variables,
		context: { headers }
	})

	return result
}
