import { Checkout, ProductVariant } from 'etc/storefront-types'

export type PartialCheckout = Pick<
	Checkout,
	'id' | 'webUrl' | 'totalPriceV2' | 'lineItemsSubtotalPrice' | 'discountApplications'
>

export interface CartState {
	items: {
		[variantId: string]: CartItem
	}
	total: number
	checkout?: PartialCheckout
	discount?: {
		code: string
	}
	ready: boolean
	lastAction?: {
		type: 'ADD' | 'EDIT' | 'REMOVE'
		itemId: string
		timestamp?: number
	}
}

export interface CartItem {
	id: string
	handle: string
	variant: ProductVariant
	title: string
	quantity: number
	vendor: string
	note?: string
	estimated_delivery: {
		days?: string
		weeks?: string
	}
	is_alcohol: boolean
	is_coupon: boolean
}

export const CART_ADD_ITEM = 'CART_ADD_ITEM'
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM'
export const CART_EDIT_QUANTITY = 'CART_EDIT_QUANTITY'
export const CART_CREATE_CHECKOUT_REQUEST = 'CART_CREATE_CHECKOUT_REQUEST'
export const CART_CREATE_CHECKOUT_SUCCESS = 'CART_CREATE_CHECKOUT_SUCCESS'
export const CART_UPDATE_CHECKOUT = 'CART_UPDATE_CHECKOUT'
export const CART_APPLY_DISCOUNT = 'CART_APPLY_DISCOUNT'
export const CART_REMOVE_DISCOUNT = 'CART_REMOVE_DISCOUNT'
export const CART_EDIT_NOTE = 'CART_EDIT_NOTE'
export const CART_CLEAR = 'CART_CLEAR'

interface AddItemAction {
	type: typeof CART_ADD_ITEM
	item: CartItem
	timestamp: number
}

interface RemoveItemAction {
	type: typeof CART_REMOVE_ITEM
	id: string
}

interface EditQuantityAction {
	type: typeof CART_EDIT_QUANTITY
	id: string
	quantity: number
}

interface CreateCheckoutRequestAction {
	type: typeof CART_CREATE_CHECKOUT_REQUEST
}

interface CreateCheckoutSuccessAction {
	type: typeof CART_CREATE_CHECKOUT_SUCCESS
	checkout: PartialCheckout
}

interface UpdateCheckoutAction {
	type: typeof CART_UPDATE_CHECKOUT
	checkout: PartialCheckout
}

interface ApplyDiscountAction {
	type: typeof CART_APPLY_DISCOUNT
	code: string
}

interface RemoveDiscountAction {
	type: typeof CART_REMOVE_DISCOUNT
}

interface EditNoteAction {
	type: typeof CART_EDIT_NOTE
	id: string
	note?: string
}

interface ClearCartAction {
	type: typeof CART_CLEAR
}

export type CartActions =
	| AddItemAction
	| RemoveItemAction
	| EditQuantityAction
	| CreateCheckoutRequestAction
	| CreateCheckoutSuccessAction
	| UpdateCheckoutAction
	| ApplyDiscountAction
	| RemoveDiscountAction
	| EditNoteAction
	| ClearCartAction
